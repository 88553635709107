import Particles from 'react-particles-js'

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <div className='blur'></div>
        <div className='content-body'>
          <img src={logo} className="App-logo" alt="logo" />
          <span>Estamos trabalhando em um novo projeto, em breve estaremos no ar novamente.</span>
          <div className="animation">
            <Particles height='40vh' width='40vw' params={{
              "particles": {
                "number": {
                  "value": 150,
                  "density": {
                    "enable": true,
                    "value_area": 1600
                  }
                },
                "color": {
                  "value": "#ffffff"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 25
                  },
                },
                "opacity": {
                  "value": 0.7,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 0.1,
                    "opacity_min": 0.1,
                    "sync": true
                  }
                },
                "size": {
                  "value": 5,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": true
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 200,
                  "color": "#ffffff",
                  "opacity": 0.4,
                  "width": 0.5
                },
                "move": {
                  "enable": true,
                  "speed": 2,
                  "direction": "none",
                  "random": true,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": true,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": false,
                    "mode": "repulse"
                  },
                  "onclick": {
                    "enable": false,
                    "mode": "push"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 150,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 250,
                    "duration": 0.5
                  },
                  "push": {
                    "particles_nb": 10
                  },
                  "remove": {
                    "particles_nb": 4
                  }
                }
              },
              "retina_detect": true
            }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
